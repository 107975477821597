
import {defineComponent} from 'vue'
export default defineComponent({
  data() {
    return {

    }
  },
  computed: {},
  mounted() {

  },
  methods: {

  }
})
