
import {  Vue } from 'vue-class-component';
import User from "@/beans/jcloud/User";
import LogUtil from "@/utils/LogUtil";
import ApiServiceImpl from '../../api/ApiServiceImpl'
import { ElMessage } from 'element-plus'
import Constants from "../../utils/Constants";
// import pubsub from 'pubsub-js';
export default class UserLogin extends Vue {
  private usr: User = new User()
  mounted(){
  }
  userLogin(){
    LogUtil.log(this.usr)
    ApiServiceImpl.getApiService().login(this.usr.email!,this.usr.password!,{
      onFailed(error: string): void {
        ElMessage.error(error)
      }, onSuccess(token: string): void {
        //pubsub.publish('loginSuccess',token)
        ElMessage.success(token)
        sessionStorage.setItem(Constants.tokenName,token)
      }
    })
  }
}
