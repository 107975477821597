import LogUtil from "@/utils/LogUtil"
import 'reflect-metadata'
import Constants from "@/utils/Constants"
enum FieldType{
    NUMBER=0,STRING=1,BOOL=2,TextArea=3,SelectOption=4,ARRAY=5,PASSWORD=6
}

class FieldStructure{
    fieldName:string
    fieldLabel:string
    extraData:string
    type:FieldType

    constructor(fieldName: string,label:string,exData:string, fieldType: FieldType) {
        this.fieldName = fieldName
        this.type = fieldType
        this.fieldLabel = label
        this.extraData = exData
    }
}

class FormsHolder{
    static getBeanFieldsStructures(instance:object):FieldStructure[]|undefined|null{
        LogUtil.log("in getBeanFieldsStructures!")
        const fields =new Array<FieldStructure>()
        for (const k in instance) {
            //const val = (instance as any)[k]
            const valType = Reflect.getMetadata(Constants.reflectKey,instance,k.toString())
            if(valType === null || valType === undefined){
                continue
            }
            const validator:ValidateBase = valType as ValidateBase
            LogUtil.log("valType:", validator.type)
            fields.push(new FieldStructure((k+''),validator.displayLabel,validator.extraData,validator.type))
        }

        return fields
    }
}
interface FieldValidationCallback{
    onValid(displayName:string,result:ValidateStatus):boolean
}
interface ValidateBase{
    displayLabel:string
    type:FieldType
    extraData: string
    validate(value:object):ValidateStatus
}

function bindValue( metadataValue: any, target: Object, propertyKey: string | symbol): void{
    // @ts-ignore
    Reflect.defineMetadata(Constants.reflectKey, metadataValue, target,propertyKey)
}

class ValidateStatus{
    success:boolean = false
    msg?:string
    constructor(suc:boolean,ms:string) {
        this.success = suc
        this.msg = ms
    }
}
class NumberValidate implements ValidateBase{
    minValue:number
    maxValue:number
    type: FieldType
    displayLabel:string
    extraData: string=''
    constructor(min:number,max:number,type_:FieldType = FieldType.NUMBER,label:string='') {
        this.type = type_
        this.displayLabel = label
        this.minValue = min
        this.maxValue = max
    }
    validate(value:object):ValidateStatus {
       const val:number =  Number(value+'')
        if(val >= this.minValue  && val <= this.maxValue){
            return new ValidateStatus(true,"")
        }
        return new ValidateStatus(false,value+" is not between "+this.minValue+" and "+this.maxValue)
    }


}
// function NumberValidator(minValue:number,maxValue:number,label:string='') {
function NumberValidator(label:string='',minValue:number=Number.MIN_VALUE,maxValue:number =Number.MAX_VALUE) {
    return function (target: Function, propertyKey: string, descriptor: PropertyDescriptor) {
        bindValue(new NumberValidate(minValue,maxValue,FieldType.NUMBER,label),target,propertyKey)
    }
}

function EmailValidator(label:string='') {
    return function (target: Function, propertyKey: string, descriptor: PropertyDescriptor) {
        bindValue(new EmailValidate(label),target,propertyKey)
    }
}

function PasswordValidator(label:string='') {
    return function (target: Function, propertyKey: string, descriptor: PropertyDescriptor) {
        bindValue(new PasswordValidate(FieldType.PASSWORD,label),target,propertyKey)
    }
}

function BoolValidator(label:string='',exData:string='') {
    return function (target: Function, propertyKey: string, descriptor: PropertyDescriptor) {
        bindValue(new BoolValidate(label,exData),target,propertyKey)
    }
}
class BoolValidate implements ValidateBase{
    type: FieldType
    displayLabel:string
    extraData: string
    constructor(label:string='',exData:string) {
        this.displayLabel = label
        this.type=FieldType.BOOL
        this.extraData=exData
    }
    validate(value:object):ValidateStatus {
        return new ValidateStatus(true,"")
    }


}
type StringLengthValidatorOptions={
    minLength:number,
    maxLength:number,
    label:string
}
function StringLengthValidator(opt:StringLengthValidatorOptions) {
    return function (target: Function, propertyKey: string, descriptor: PropertyDescriptor) {
        bindValue(new StringLengthValidate(opt.minLength,opt.maxLength,opt.label),target,propertyKey)
    }
}
class StringLengthValidate implements ValidateBase{
    minLength:number
    maxLength:number
    type: FieldType
    displayLabel:string
    extraData: string=''
    constructor(min:number,max:number,label:string='') {
        this.displayLabel = label
        this.type=FieldType.STRING
        this.minLength = min
        this.maxLength = max
    }
    validate(value:object):ValidateStatus {
        const leng = (value+"").length
        if(leng >= this.minLength  && leng <= this.maxLength){
            return new ValidateStatus(true,"")
        }
        return new ValidateStatus(false,"Length of "+value+" is not between "+this.minLength+" and "+this.maxLength)
    }
}

function StringRegexValidator(reg:string='',label:string='',exData:string='',type_: FieldType=FieldType.STRING) {
    return function (target: Function, propertyKey: string, descriptor: PropertyDescriptor) {
        bindValue(new StringRegexValidate(reg,label,exData,type_),target,propertyKey)
    }
}
class StringRegexValidate implements ValidateBase{
    type: FieldType
    regex:string
    displayLabel:string
    extraData: string
    constructor(reg:string,label:string='',exData:string='',type_: FieldType=FieldType.STRING) {
        this.displayLabel = label
        this.regex = reg
        this.type=type_
        this.extraData=exData;
    }
    validate(value:object):ValidateStatus {
        if ((value+"").match(this.regex)) {
            return new ValidateStatus(true,"")
        }
        return new ValidateStatus(false,value+" does not match "+this.regex)
    }
}

class EmailValidate implements ValidateBase{
    regex:string = "^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\\.[a-zA-Z0-9_-]+)+$"
    type: FieldType
    displayLabel:string
    extraData: string=''
    constructor(label:string='') {
        this.displayLabel = label
        this.type = FieldType.STRING;
    }

    validate(value:object):ValidateStatus {
        if ((value+"").match(this.regex)) {
            return new ValidateStatus(true,"")
        }
        return new ValidateStatus(false,"Not a valid email address!")
    }
}
class PasswordValidate implements ValidateBase{
    type: FieldType
    displayLabel:string
    extraData: string=''
    constructor(type_:FieldType = FieldType.STRING,label:string='') {
        this.type = type_
        this.displayLabel=label
    }
    regex:string = "^.*(?=.{6,})(?=.*\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$"
    validate(value:object):ValidateStatus {
        if ((value+"").match(this.regex)) {
            return new ValidateStatus(true,"")
        }
        return new ValidateStatus(false,"Password does not match security requirement!")
    }
}

// @ts-ignore
function validate(instance:object,cllback:FieldValidationCallback){
    if(instance === null || instance === undefined){
        return
    }
    if(cllback === null || cllback === undefined){
        return
    }
    for (const k in instance) {
        const val = (instance as any)[k]
        LogUtil.log("key:",k," value:",val)
        //function getMetadata(metadataKey: any, target: Object, propertyKey: string | symbol): any;
        //const valType = callback.onCall(Constants.reflectKey,k.toString())
        // @ts-ignore
        const valType = Reflect.getMetadata(Constants.reflectKey,instance,k.toString())
        if(valType === null || valType === undefined){
            continue
        }
        const validator:ValidateBase = valType as ValidateBase
        const result:ValidateStatus = validator.validate(val)
        if(!result.success){
            LogUtil.log(k," has invalid value:",val)
            if(cllback.onValid((validator.displayLabel ? validator.displayLabel:(k+"")),result)){
                break
            }
        }
    }

}
export {FormsHolder,FieldStructure,FieldType,BoolValidator,EmailValidator,PasswordValidator,NumberValidator,StringLengthValidator,StringRegexValidator,ValidateStatus,validate,ValidateBase,FieldValidationCallback}