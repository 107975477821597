import {FieldType,BoolValidator, EmailValidator, PasswordValidator, StringRegexValidator} from '../../utils/FormValidator'
import "reflect-metadata"

export default class User {

    // @ts-ignore
    @StringRegexValidator('','名','')
    firstName?: string


    // @ts-ignore
    @StringRegexValidator('','姓','')
    lastName?: string

    // @ts-ignore
    @EmailValidator('邮件')
    email?: string
    // @ts-ignore
    @PasswordValidator('密码')
    password?: string
    // @ts-ignore
    @PasswordValidator('确认密码')
    public confirmpassword?: string
}