import { createApp } from 'vue'
import store from './store'
import router from './router'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import MyPlugin from './utils/Plugin'
// import loadingViewerVue from 'loading-view-vue'
const app = createApp(App).use(router).use(store).use(ElementPlus).use(MyPlugin)
app.mount('#app')
