import LogUtil from "@/utils/LogUtil";

export default {
    version(){
        return '1.0.0'
    },
    install(app: import("vue").App<any>) {
        LogUtil.log("installing this plugin...")
        app.directive('bigdog',{
            mounted(el,binding){
                LogUtil.log("Customized Directives...mounted")
            },
            updated(el,binding){
                LogUtil.log("Customized Directives...updated",binding.value)
            }
        })
    }

}