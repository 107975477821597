import ApiService from '../api/ApiService'
import {LoginStatus, LogoutStatus, RegisterStatus, TokenStatus} from "@/api/LoginStatus";
import LogUtil from '../utils/LogUtil'
import axios from 'axios'
import serverHost from "@/utils/ServerHost";
import {Md5} from "md5-typescript"
export default class ApiServiceImpl implements ApiService {

    private static baseApiUrl:string=serverHost.CloudUserHost
    private static regApiUrl:string= ApiServiceImpl.baseApiUrl+'register'
    private static LoginApiUrl:string=ApiServiceImpl.baseApiUrl+'login'
    private static tokenStatusApiUrl:string=ApiServiceImpl.baseApiUrl+'tokenStatus'
    private static LogoutApiUrl:string=ApiServiceImpl.baseApiUrl+'logout'

    private static apiService:ApiService
    private constructor() {
    }
    public static getApiService():ApiService {
        if(this.apiService === null|| this.apiService === undefined){
            this.apiService = new ApiServiceImpl()
            LogUtil.log("ApiServiceImpl is null=========",this.apiService)
        }
        LogUtil.log("ApiServiceImpl=========",this.apiService)
        return this.apiService
    }
    async login(username: string, passwd: string, loginstatus: LoginStatus) {
        const x: string = await this.loginAPI(username,passwd,loginstatus)
        //loginstatus.onSuccess(x)
    }

   private loginAPI(email: string, passwd: string, loginstatus: LoginStatus): Promise<string> {
        return new Promise(resolve => {

            axios.post(ApiServiceImpl.LoginApiUrl,JSON.stringify({email,'password':Md5.init(passwd)}),{headers:{
                    "Content-Type": "application/json"
                }}).then(response => {
                LogUtil.log("server response:", response.data)
                if(response.data.access_token){
                    loginstatus.onSuccess(response.data.access_token)
                }else{
                    loginstatus.onFailed(response.data.msg)
                }

            }, error => {
                loginstatus.onFailed(error.message)
                alert(error.message)

            })
        });
    }


    private RegisterAPI(email: string, password: string, registerStatus: RegisterStatus): Promise<string> {
        return new Promise(resolve => {

            axios.post(ApiServiceImpl.regApiUrl,JSON.stringify({'name':'zhangsan',email,'password':Md5.init(password)}),{headers:{
                    "Content-Type": "application/json"
                }}).then(response => {
                console.log("server response:", response.data)
                if(response.data.status === 'ok'){
                    registerStatus.onSuccess(response.data.id)
                }else{
                    registerStatus.onFailed(response.data.msg)
                }

            }, error => {
                registerStatus.onFailed(error.message)
                alert(error.message)

            })

        })
    }

    async register(email: string, password: string, registerStatus: RegisterStatus) {
        const x: string = await this.RegisterAPI(email,password,registerStatus)
        //registerStatus.onSuccess('...')
    }

    async logOut(access_token: string,s:LogoutStatus) {
        await axios.post(ApiServiceImpl.LogoutApiUrl,{},{params:{
            access_token
            }}).then(response => {
            console.log("server response in success:", response.data)
            s.onDone()
        }, error => {
            console.log("server response in error:", error.data)
        })
    }
    async tokenStatus(access_token: string,tokenStatus:TokenStatus) {
        await axios.post(ApiServiceImpl.tokenStatusApiUrl,{},{params:{
            access_token
            }}).then(response => {
            console.log("server response in success:", response.data)
            if(response.data.status){
                tokenStatus.onValid()
            }else {
                tokenStatus.onInValid()
            }
        }, error => {
            console.log("server response in error:", error.data)
        })
    }


}