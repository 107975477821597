import {createRouter,createWebHistory,} from 'vue-router'  //引入vue-router


import Login from "../pages/CloudUser/Login.vue"
import Register from "../pages/CloudUser/Register.vue"
import ForgotPassword from "../pages/CloudUser/ForgotPassword.vue"
import Constants from "@/utils/Constants";
//
const router:any = createRouter({
    history:createWebHistory(),
    routes:[
        {
            path:'/Login',
            name:'Login',
            component:Login,
            meta:{
                title:'用户登录',
                isAuth:false
            }
        },{
            path:'/Register',
            name:'Register',
            component:Register,
            meta:{
                title:'用户注册',
                isAuth:false
            }
        },{
            path:'/ForgotPassword',
            name:'ForgotPassword',
            component:ForgotPassword,
            meta:{
                title:'忘记密码',
                isAuth:false
            }
        },{
            //default router
            path:'/',
            redirect:'/Login'
        }
    ]

})

// @ts-ignore
router.beforeEach((to,from,next)=>{
    console.log("beforeEach....",to.path)

    if(to.meta.isAuth){
        console.log("applying router protection.......",to.path)
        if(sessionStorage.getItem(Constants.tokenName)){
            next()
        }
    }else{
        next()
    }
    //if(to.path==='/subrouter/sub1'){if(localStorage.getItem('token')==='abc'){next()} }else{ next()}
})

router.afterEach((to:any,from:any)=>{
    document.title=to.meta.title||'myTitle'
    console.log("afterEach....")
})
export default router