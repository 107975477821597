import axios from 'axios'
import { nanoid } from 'nanoid'
const testOptions = {
    namespaced: true,
    state() {
        return {
            count: 0
        }
    },
    mutations: {
        increment(state:any) {
            state.count++
        },
        add(state:any, value:any) {
            console.log("add.... in mutations")
            state.count++
        }
    },
    actions: {
        add(context:any, value:any) {
            console.log("add.... in actions")
            context.commit("add", value)
        },
        serverApi(context:any) {
            axios.get("https://api.uixsj.cn/hitokoto/get?type=social").then(response => {
                console.log("server response:", response.data)
            }, error => {
                alert(error.message)
            })
        }
    },
    getters: {
        getComplexValue(state:any) {
            return state.count + 8
        }
    }
}
export default testOptions