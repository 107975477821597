
import { defineComponent } from 'vue'
import { ElMessage } from 'element-plus'
import LogUtil from "@/utils/LogUtil"
import User from "../../beans/jcloud/User"
import formTemplate from "../../beans/AutoFormTemplate"
import 'reflect-metadata';
// import ApiService from '../../api/ApiService'
import ApiServiceImpl from '../../api/ApiServiceImpl'
import {validate,ValidateStatus} from '@/utils/FormValidator'
// import DynamicForm from '@/components/DynamicForm.vue'
export default defineComponent({
  components:{
    // DynamicForm
  },
  data() {
    return {
      user:new User()
    }
  },
  mounted() {
  },
  methods:{
    userRegister(){
      LogUtil.log('in userRegister....',this.user.email,this.user.password,this.user.confirmpassword)
      let hasError:boolean = false
      validate( this.user,{
        onValid(displayName: string, result: ValidateStatus): boolean {
          hasError = true
          ElMessage.error((displayName)+":"+result.msg)
          return true
        }
      })
      if(hasError){
        return
      }
      console.log(JSON.stringify(this.user))
      ApiServiceImpl.getApiService().register(this.user.email!,this.user.password!,{
        onFailed(error: string): void {
          ElMessage.error(error)
          LogUtil.log('register.onFailed',error)
        },
        onSuccess(usrId: string): void {
          ElMessage.error(' user register Successfully!')
          LogUtil.log('register.onSuccess',usrId)
        }
      })
      //ElMessage.success("user register Successfully")
    }
    ,
    delivery_Data(target:object,jsn:string=''){
      console.log("target:",JSON.stringify(target))
      ElMessage.success(jsn)
    }
  }
})
